import { Injectable } from '@angular/core';
import { IUser } from './auth.models';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { CrudHelper } from '../firebase/helpers/crudHelper';
import { MapService } from '../services/map.service';
import mixpanel from 'mixpanel-browser';
import { countries } from '../auth/sign-up/countries';
import { getFunctions, httpsCallable } from 'firebase/functions';
import {
  browserLocalPersistence,
  browserSessionPersistence,
  createUserWithEmailAndPassword,
  getAuth,
  sendPasswordResetEmail,
  setPersistence,
  signInWithEmailAndPassword,
  signOut,
} from 'firebase/auth';
import { Capacitor } from '@capacitor/core';
import { environment } from 'src/environments/environment';
import { ProfileStore } from '../profile/store/profile-store';
import { map, catchError, take, filter, tap } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  newUser = {
    uid: null,
    email: null,
    username: null,
    country: null,
    city: null,
    postalCode: null,
    location,
    profilePicture: null,
    gender: 'Other',
    mermaidExpLvl: null,
    mersonaName: null,
    galleryVisibility: true,
    isMailingEnabled: true,
    platform: Capacitor.getPlatform(),
  };

  properties;

  constructor(
    private fireStore: AngularFirestore,
    private profileStore: ProfileStore,
    private mapService: MapService,
    private router: Router
  ) {}

  signUp = async user => {
    const country = countries.find(
      country => country.name === user.address.country
    );
    const location = this.mapService.createCoordinates(
      user.address.googleMapLink
    );

    return new Promise(async (resolve, reject) => {
      const auth = getAuth();
      const functions = getFunctions();

      const isUsernameTaken = httpsCallable(functions, 'isUsernameTaken');

      isUsernameTaken({ username: user.username })
        .then(({ data }) => {
          data
            ? reject('auth/username-already-exist')
            : createUserWithEmailAndPassword(auth, user.email, user.password)
                .then(async userCredential => {
                  resolve(
                    this.fireStore.doc(`users/${userCredential.user.uid}`).set({
                      ...this.newUser,
                      uid: userCredential.user.uid,
                      email: user.email,
                      username: user.username,
                      country,
                      location,
                      isMailingEnabled: user.isMailingEnabled,
                    })
                  );
                  mixpanel.init(environment.mixpanelToken, { debug: true });
                  mixpanel.identify(userCredential.user.uid);
                  this.trackEvent('New User', userCredential, user, country);

                  mixpanel.people.set({
                    Map_Region: country.region,
                    Map_Country_Code: country.code,
                    Map_Country: user.address.country,
                    $email: user.email,
                    $name: user.username,
                    $createdAt: new Date(),
                    'marketing-accept': user.isMailingEnabled,
                    $subscription_status: 'not subscribed',
                    'premium-active': false,
                  });
                })
                .catch(error => reject(error.code));
        })
        .catch(error => reject(error.code));
    });
  };

  signIn = async ({ email, password, rememberMe }) => {
    return new Promise(async (resolve, reject) => {
      const auth = getAuth();
      const sessionType = rememberMe
        ? browserLocalPersistence
        : browserSessionPersistence;

      setPersistence(auth, sessionType).then(() => {
        signInWithEmailAndPassword(auth, email, password)
          .then(userCredential => {
            resolve(userCredential);
          })
          .catch(error => reject(error.code));
      });
    });
  };

  signOut = async () => {
    signOut(getAuth())
      .then(() => {
        this.router.navigate(['/'], { replaceUrl: true });
        this.profileStore.logOut();
        console.log('successfully sign out');
      })
      .catch(error => Promise.reject(error.code));
  };

  sendResetEmail({ email }) {
    const auth = getAuth();
    sendPasswordResetEmail(auth, email)
      .then(() => {
        console.log('Password reset email sent');
      })
      .catch(error => {
        console.log(error.message);
      });
  }

  trackEvent(event, userCredential, user, country) {
    this.properties = {
      id: userCredential.user.uid,
      country: country,
      region: country.region,
      city: user.address.city,
      email: user.email,
      'marketing-accept': user.isMailingEnabled,
      'premium-active': false,
    };

    mixpanel.track(event, this.properties);
  }
}
