import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { IUser } from '../auth.models';
import { IonInput, ToastController } from '@ionic/angular';
import { BehaviorSubject, Subject } from 'rxjs';
import { countries } from './countries';
import { takeUntil } from 'rxjs/operators';
import { FormService } from 'src/app/shared/components/form/form.service';
import { MapService } from 'src/app/services/map.service';
import { AuthService } from '../auth.service';

export interface ICountry {
  name: string;
  code: string;
}

export function matchValidator(
  matchTo: string,
  reverse?: boolean
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.parent && reverse) {
      const c = (control.parent?.controls as any)[matchTo] as AbstractControl;
      if (c) {
        c.updateValueAndValidity();
      }
      return null;
    }
    return !!control.parent &&
      !!control.parent.value &&
      control.value === (control.parent?.controls as any)[matchTo].value
      ? null
      : { matching: true };
  };
}

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
})
export class SignUpComponent implements OnInit, OnDestroy {
  @ViewChild('inputCity') inputCity: IonInput;
  form: FormGroup;
  countries$ = new BehaviorSubject<ICountry[]>([]);
  type = 'password';
  iconName = 'eye';
  typeConfirm = 'password';
  iconNameConfirm = 'eye';
  isValidLink = true;
  private loadSpinner = new BehaviorSubject(false);
  loadSpinner$ = this.loadSpinner.asObservable();
  private destroy$ = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private toastController: ToastController,
    private formService: FormService,
    private mapService: MapService
  ) {}

  ngOnInit() {
    this.formService.location$.next({ googleMapLink: null });
    this.form = this.fb.group(
      {
        username: ['', Validators.required],
        password: [
          '',
          [
            Validators.required,
            Validators.minLength(6),
            matchValidator('confirmPassword', true),
          ],
        ],
        repeatPassword: ['', [Validators.required, matchValidator('password')]],
        conditions: [false, Validators.requiredTrue],
        privacyPolicy: [false, Validators.requiredTrue],
        isMailingEnabled: [false],
        email: ['', [Validators.required, Validators.email]],
        address: this.fb.group({
          country: [''],
          googleMapLink: ['', [Validators.required]],
        }),
      },
      {
        validators: [matchValidator],
      }
    );
    this.countries$.next(countries);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  async addMapLink(e) {
    e.valueChanges.subscribe(async () => {
      this.isValidLink = true;
      const location = e.get('googleMapLink')?.value;
      const { country } = await this.mapService.getAdDress(location);
      if (!country) {
        this.isValidLink = false;
      }

      this.form.get('address.googleMapLink')?.patchValue(location);
      this.form.get('address').patchValue({ country });
    });
  }

  async signUp() {
    this.loadSpinner.next(true);

    const user: IUser = this.form.value;
    this.authService
      .signUp(user)
      .then(() => {
        this.router.navigate(['/tutorial'], { replaceUrl: true });
      })
      .catch(error => {
        switch (error) {
          case 'auth/email-already-in-use':
            this.createToaster('User email already exists');
            break;
          case 'auth/invalid-email':
            this.createToaster('Email address is not valid');
            break;
          case 'auth/weak-password':
            this.createToaster('Password is not strong enough');
            break;
          case 'auth/operation-not-allowed':
            this.createToaster('Operation not allowed');
            break;
          case 'auth/username-already-exist':
            this.createToaster('Username already exist');
            break;
          case 'auth/invalide-address':
            this.createToaster('Address is not valid');
            break;
          default:
            this.createToaster('Firebase error');
        }
      })
      .finally(() => {
        this.loadSpinner.next(false);
      });
  }

  changeType() {
    this.type = this.type === 'password' ? 'text' : 'password';
    this.iconName = this.iconName === 'eye' ? 'eye-off' : 'eye';
  }

  changeTypeConfirm() {
    this.typeConfirm = this.typeConfirm === 'password' ? 'text' : 'password';
    this.iconNameConfirm = this.iconNameConfirm === 'eye' ? 'eye-off' : 'eye';
  }

  private createToaster(message: string) {
    this.toastController
      .create({
        message,
        color: 'danger',
        duration: 1500,
        position: 'top',
      })
      .then(t => t.present());
  }
}
